import React from "react"
import styled from "styled-components"
import svgTwitterWhite from "../svg/socials/twitter-white.svg"

const Wrapper = styled.div`
  text-align: center;

  /* position: sticky; */
  bottom: 2%;

  padding: 0 ${props => props.theme.sideSpace.contentSmall};
  /* @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    padding: 0 ${props => props.theme.sideSpace.contentSmall};
  } */
`

const ShareLinks = styled.div`
  margin-top: 0.5em;
`

const ShareLink = styled.a`
  display: inline-block;
  margin: 0 6px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: #fff;
  background: #00aced;
  font-weight: 700;
  vertical-align: middle;
  &:hover {
    transform: translateY(-2px);
  }
`

const ShareButtons = ({ slug, title }) => {
  const encodedTitle = encodeURIComponent(`${title} | イングリッス.com`)
  const pageUrl = `https://englissu.com${slug}`

  return (
    <Wrapper>
      <ShareLinks>
        <ShareLink
          href={`https://twitter.com/share?url=${pageUrl}&text=${encodedTitle}&via=englissu`}
          rel="nofollow"
        >
          <img
            src={svgTwitterWhite}
            alt="Twitter"
            style={{
              width: "24px",
              height: "19px",
              marginTop: "11px",
            }}
          />
        </ShareLink>
        <ShareLink
          href={`https://www.facebook.com/share.php?u=${pageUrl}`}
          style={{
            fontSize: "20px",
            background: "#1877f2",
          }}
          rel="nofollow"
        >
          f
        </ShareLink>
        <ShareLink
          href={`http://b.hatena.ne.jp/add?mode=confirm&url=${pageUrl}`}
          style={{
            fontSite: "19px",
            background: "#008fde",
          }}
          rel="nofollow"
        >
          B!
        </ShareLink>
      </ShareLinks>
    </Wrapper>
  )
}

export default ShareButtons
