import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Helmet from "react-helmet"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import CategoryLabel from "../components/CategoryLabel"
import TagLabel from "../components/TagLabel"

import PostJsonLd from "../components/json/PostJsonLd"
import ShareButtons from "../components/ShareButtons"

import postContentStyle from "../styles/postContent"
import postCustomBlockStyle from "../styles/postCustomBlock"
import postSyntaxHighlightStyle from "../styles/postSyntaxHighlight"

import {
  GoogleAdsense0,
  GoogleAdsense1,
  GoogleAdsense2,
  GoogleAdsense3,
  GoogleAdsense4,
  GoogleAdsenseEnd,
} from "../components/GoogleAdsense"
import {
  TestbaseLinkCard,
  StudysapuriElementaryJunior,
  StudysapuriHighschool,
  StudysapuriEnglishCoaching,
  StudysapuriEnglish,
  StudysapuriEnglishBusiness,
  KodomoChallenge,
  ShinkenzemiElementary,
  Zkai,
  Rarejob,
  Eigox,
  Tsushinsei,
} from "../components/AffiliateLinks"

import rehypeReact from "rehype-react"
import SuggestedPost from "../components/SuggestedPosts"

const Content = styled.section`
  position: relative;
  background: #fff;
  /* overflow: hidden; */
  font-size: 16px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    z-index: 5;
  }

  @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    margin: 0 -${(props) => props.theme.sideSpace.small};
    &:before,
    &:after {
      content: none;
    }
  }
`

const ContentMain = styled.div`
  padding: 1.8em ${(props) => props.theme.sideSpace.contentLarge};
  @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    padding: 5px ${(props) => props.theme.sideSpace.contentSmall};
  }

  .featured-image {
    margin-top: 25px;
  }
`

const PostTitle = styled.h1`
  margin: 0.1em 0 0.3em;
  font-size: 1.8em;
  @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    font-size: 25px;
  }
  font-weight: 700;
  line-height: 1.5;
`

const PostContent = styled.div`
  ${postContentStyle}
  ${postCustomBlockStyle}
  ${postSyntaxHighlightStyle}
`

const Labels = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const PostDate = styled.time`
  display: block;
  color: ${(props) => props.theme.colors.silver};
  font-size: 13px;
  margin-top: 5px;
  letter-spacing: 0.05em;
`

const AuthorImage = styled.img`
  margin-right: 10px;
  float: left;

  &.small {
    width: 54px;
    height: 54px;
  }

  &.large {
    width: 80px;
    height: 80px;
  }
`

const AuthorName = styled.p`
  display: inline-block;
  color: #000;
  font-size: 12px;
  margin-top: 10px;
`
const AuthorName_end = styled.p`
  display: inline-block;
  color: #000;
  font-size: 28px;
  line-height: 80px;
  margin-top: 0;

  @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    font-size: 18px;
  }
`

const AuthorProfile = styled.p.attrs((props) => ({
  style: { color: props.theme.colors.silver },
}))`
  display: inline-block;

  font-size: 10px;
  vertical-align: top;
`

const AuthorStatus = styled.p`
  display: inline-block;
  margin-left: 2px;
  padding: 0 3px;
  font-size: 10px;
  font-weight: bold;
  font-style: italic;
  transform: scale(0.8);
  color: #fff;
  background-color: #000;
`

const AuthorDetailedProfile = styled.p`
  font-size: 12px;
  color: gray;
  position: relative;
  top: -10px;
  margin-left: 90px;
`

const ShareTitle = styled.div`
  font-weight: 700;
  font-size: 1.2em;
  letter-spacing: 0.05em;
  text-align: center;
  margin: 1.5em 0 0;
`

const TwitterFollowButton = styled.a`
  display: inline-block;
  padding: 0 8px;
  margin-left: 12px;

  border: 1px solid;
  border-radius: 4px;
  border-color: rgba(0, 147, 221, 1);
  font-size: 12px;
  vertical-align: top;
  margin-top: 30px;

  color: rgba(0, 147, 221, 1);
  &:hover {
    opacity: 0.6;
  }

  cursor: pointer;
`

const PopularArticle = styled.div`
  margin-bottom: 1em;
`

const PopularText = styled.div`
  float: left;
  padding: 2px 8px 1px;
  background: #ee7917;
  color: #fff;
  border-radius: 4px;
  margin: 0 0.5em 0 0;
  font-size: 0.85em;
  font-weight: bold;
`

const WrapperForProfileAtEnd = styled.div`
  padding: 1.8em ${(props) => props.theme.sideSpace.contentLarge};
  @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    padding: 30px ${(props) => props.theme.sideSpace.contentSmall};
  }
`

class BlogPostTemplate extends React.PureComponent {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { relatedPosts, slug } = this.props.pageContext

    const { title, description, date, category, tags, authorIs } =
      post.frontmatter

    const siteUrl = this.props.data.site.siteMetadata.siteUrl

    const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: {
        adsense: GoogleAdsense0,
        adsense1: GoogleAdsense1,
        adsense2: GoogleAdsense2,
        adsense3: GoogleAdsense3,
        adsense4: GoogleAdsense4,
        testbaselinkcard: TestbaseLinkCard,
        studysapurielementaryjunior: StudysapuriElementaryJunior,
        studysapurihighschool: StudysapuriHighschool,
        studysapurienglish: StudysapuriEnglish,
        studysapurienglishcoaching: StudysapuriEnglishCoaching,
        studysapurienglishbusiness: StudysapuriEnglishBusiness,
        kodomochallenge: KodomoChallenge,
        shinkenzemielementary: ShinkenzemiElementary,
        zkai: Zkai,
        rarejob: Rarejob,
        eigox: Eigox,
        tsushinsei: Tsushinsei,
      },
    }).Compiler

    let authorName = ""
    let authorProfile = ""
    let authorDetailedProfile = ""
    let authorStatus = ""
    let twitterUrl = ""
    let authorImg = ""

    if (authorIs === "watson") {
      authorName = "りっすん＠英語教師"
      authorProfile = "高校英語教師（７年目）/ 英検1級"
      authorDetailedProfile =
        "教師歴７年目🇬🇧英検１級取得🇺🇸教師が幸せになれば子供たちも幸せになるはず🌸教育現場を変えていき明るい未来を作っていきたい🗽ブログ・YouTubeで英語勉強法・教育現場について発信中📖🎥"
      authorStatus = "PRO"
      twitterUrl = "https://twitter.com/RissunEnglish"
      authorImg = `${siteUrl}/png/profile-watson.png`
    } else if (authorIs === "manager") {
      authorName = "サイト管理人"
      authorProfile = "イングリッス.com 管理人"
      authorDetailedProfile =
        "海外旅行が大好きなバックパッカー🚀今までに20か国以上を旅してきました🚶‍英語はこれから勉強頑張ります😇主にサイトの運営・管理を担当します"
      authorStatus = "PRO"
      twitterUrl = "https://twitter.com/englissu"
      authorImg = `${siteUrl}/png/profile-manager.png`
    } else if (authorIs === "evgeni") {
      authorName = "Evgeni @ ブルガリア"
      authorProfile = "ブルガリア出身のエンジニア"
      authorDetailedProfile =
        "ブルガリアの首都ソフィアで勉強しながら仕事をしています。"
      authorStatus = "GUEST"
      twitterUrl = "https://twitter.com/"
      authorImg = `${siteUrl}/png/profile-evgeni.png`
    } else if (authorIs === "thuha") {
      authorName = "Thuha @ スウェーデン"
      authorProfile = "スウェーデンで暮らしています😊"
      authorDetailedProfile =
        "経験はあらゆる方法であなたをカタチ作るものだから、あなたが怖いからと思ったからって簡単にノーと言っちゃダメだよ😚"
      authorStatus = "GUEST"
      twitterUrl = "https://twitter.com/"
      authorImg = `${siteUrl}/png/profile-thuha.png`
    } else {
      authorName = "ゲスト" //名前やprofileを追加していく
      authorProfile = ""
      authorStatus = "GUEST"
      twitterUrl = ""
      authorImg = `${siteUrl}/png/profile-teacher.png`
    }
    const authorName_end = authorName

    let popularLink1 = ""
    let popularTitle1 = ""
    let popularLink2 = ""
    let popularTitle2 = ""

    popularLink1 = "/testbase-review/"
    popularTitle1 =
      "【学校・塾にオススメ】学習管理サービス Testbase はかなり使える"
    popularLink2 = "/amazon-prime-student/"
    popularTitle2 =
      "Amazon prime の学割がお得すぎる！メリット・デメリットをわかりやすく解説！"

    // const sizes = post.frontmatter.featuredImage
    //   ? post.frontmatter.featuredImage.childImageSharp.fluid
    //   : 0
    const image = getImage(post.frontmatter.featuredImage)

    const { categories } = this.props.data.site.siteMetadata
    const categoryObject = categories.find((cat) => {
      return cat.slug === category
    })
    const categoryColor = categoryObject ? categoryObject.color : "#555"

    const imageUrl = post.frontmatter.featuredImage.childImageSharp.fluid
    // const imageUrl = post.frontmatter.featuredImage.childImageSharp.fluid.src

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={title}
          description={description || post.excerpt}
          imageUrl={imageUrl}
        />
        <Helmet>
          <link
            rel="canonical"
            href={`https://englissu.com${
              this?.props?.location?.pathname ?? ""
            }`}
          />

          <script async src="//cdn.iframe.ly/embed.js" charSet="utf-8" />
        </Helmet>
        <PostJsonLd
          title={title}
          description={description || post.excerpt}
          date={date}
          url={this.props.location.href}
          categorySlug={category}
        />
        <Content>
          <ContentMain>
            <PostTitle>{title}</PostTitle>
            <PostDate>投稿：{date}</PostDate>
            <Labels>
              <CategoryLabel slug={category} isLink="true" />
              {tags.map((tag, index) => (
                <TagLabel key={index} slug={tag} isLink="true" />
              ))}
            </Labels>

            <div>
              <a href={twitterUrl}>
                <AuthorImage src={authorImg} className="small" />
              </a>
              <AuthorName>{authorName}</AuthorName>
              <AuthorStatus>{authorStatus}</AuthorStatus>
              <br />
              <AuthorProfile>{authorProfile}</AuthorProfile>
            </div>

            <GatsbyImage image={image} className="featured-image" />

            <PostContent
              siteUrl={siteUrl}
              categoryColor={categoryColor}
              categorySlug={category}
            >
              {renderAst(post.htmlAst)}
            </PostContent>

            <br />
            <PopularArticle>
              <PopularText>人気記事</PopularText>
              <Link to={popularLink1} style={{ fontWeight: "bold" }}>
                {popularTitle1}
              </Link>
            </PopularArticle>
            <PopularArticle>
              <PopularText>人気記事</PopularText>
              <Link to={popularLink2} style={{ fontWeight: "bold" }}>
                {popularTitle2}
              </Link>
            </PopularArticle>
            <br />

            <ShareTitle>＼この記事が気に入ったらシェア／</ShareTitle>
            <ShareButtons slug={slug} title={title} />
          </ContentMain>

          <GoogleAdsenseEnd />

          <WrapperForProfileAtEnd>
            <p
              style={{
                color: "gray",
                fontSize: "15px",
                position: "relative",
                top: "20px",
                marginLeft: "90px",
              }}
            >
              WRITTEN BY
            </p>
            <div>
              <a href={twitterUrl}>
                <AuthorImage src={authorImg} className="large" />
              </a>
              <AuthorName_end>{authorName_end}</AuthorName_end>
              <TwitterFollowButton href={twitterUrl} rel="nofollow">
                Follow
              </TwitterFollowButton>
              <AuthorDetailedProfile>
                {authorDetailedProfile}
              </AuthorDetailedProfile>
            </div>
          </WrapperForProfileAtEnd>

          <SuggestedPost posts={relatedPosts} categorySlug={category} />
        </Content>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query blogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
        categories {
          name
          slug
          color
        }
      }
    }

    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      frontmatter {
        title
        description
        date(formatString: "YYYY.MM.DD")
        category
        tags
        authorIs
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 630)
          }
        }
      }
    }
  }
`
