import React from "react"

export const TestbaseLinkCard = () => (
  <div class="iframely-embed">
    <div class="iframely-responsive">
      <a
        href="https://testbase.app/login"
        data-iframely-url="//iframely.net/kBAr4RB"
      ></a>
    </div>
  </div>
)

export const StudysapuriElementaryJunior = () => (
  <a
    href="https://px.a8.net/svt/ejp?a8mat=3BG742+G6VE0I+36T2+TWLPU"
    rel="nofollow"
  >
    成績アップの新常識！リクルート【スタディサプリ小学・中学講座】
    <img
      border="0"
      width="1"
      height="1"
      src="https://www11.a8.net/0.gif?a8mat=3BG742+G6VE0I+36T2+TWLPU"
      alt=""
    />
  </a>
)

export const StudysapuriHighschool = () => (
  <a
    href="https://px.a8.net/svt/ejp?a8mat=2ZNL6P+ACPDGY+36T2+62U36"
    rel="nofollow"
  >
    神授業が見放題！【スタディサプリ大学受験講座】
    <img
      border="0"
      width="1"
      height="1"
      src="https://www15.a8.net/0.gif?a8mat=2ZNL6P+ACPDGY+36T2+62U36"
      alt=""
    />
  </a>
)

export const StudysapuriEnglish = () => (
  <a href="https://h.accesstrade.net/sp/cc?rk=0100k0eu00j45i" rel="nofollow">
    スタディサプリENGLISH TOEIC®対策
    <img
      src="https://h.accesstrade.net/sp/rr?rk=0100k0eu00j45i"
      width="1"
      height="1"
      border="0"
      alt=""
    />
  </a>
)

export const StudysapuriEnglishCoaching = () => (
  <a href="https://h.accesstrade.net/sp/cc?rk=0100ngcu00j45i" rel="nofollow">
    スタディサプリEnglish コーチングプラン
    <img
      src="https://h.accesstrade.net/sp/rr?rk=0100ngcu00j45i"
      width="1"
      height="1"
      border="0"
      alt=""
    />
  </a>
)

export const StudysapuriEnglishBusiness = () => (
  <a
    href="https://px.a8.net/svt/ejp?a8mat=3BG742+G5OISY+3AQG+TVBF6"
    rel="nofollow"
  >
    スタディサプリEnglish　ビジネス英会話コース
    <img
      border="0"
      width="1"
      height="1"
      src="https://www12.a8.net/0.gif?a8mat=3BG742+G5OISY+3AQG+TVBF6"
      alt=""
    />
  </a>
)

export const KodomoChallenge = () => (
  <a href="https://h.accesstrade.net/sp/cc?rk=0100kxnv00j45i" rel="nofollow">
    ベネッセこどもちゃれんじ 資料請求
    <img
      src="https://h.accesstrade.net/sp/rr?rk=0100kxnv00j45i"
      width="1"
      height="1"
      border="0"
      alt=""
    />
  </a>
)

export const ShinkenzemiElementary = () => (
  <a href="https://h.accesstrade.net/sp/cc?rk=0100kxnu00j45i" rel="nofollow">
    ベネッセ進研ゼミ小学講座 資料請求
    <img
      src="https://h.accesstrade.net/sp/rr?rk=0100kxnu00j45i"
      width="1"
      height="1"
      border="0"
      alt=""
    />
  </a>
)
// export const ShinkenzemiElementary = () => (
//   <a href="https://h.accesstrade.net/sp/cc?rk=0100kxnu00j45i" rel="nofollow">
//     ベネッセ進研ゼミ小学講座 資料請求
//     <img src="https://h.accesstrade.net/sp/rr?rk=0100kxnu00j45i" width="1" height="1" border="0" alt="" />
//   </a>
// )
// export const ShinkenzemiElementary = () => (
//   <a href="https://h.accesstrade.net/sp/cc?rk=0100kxnu00j45i" rel="nofollow">
//     ベネッセ進研ゼミ小学講座 資料請求
//     <img src="https://h.accesstrade.net/sp/rr?rk=0100kxnu00j45i" width="1" height="1" border="0" alt="" />
//   </a>
// )

export const Zkai = () => (
  <a
    href="https://px.a8.net/svt/ejp?a8mat=358WX8+G4HNLE+E0Q+CUQYA"
    rel="nofollow"
  >
    Ｚ会の通信教育【資料請求】
    <img
      border="0"
      width="1"
      height="1"
      src="https://www19.a8.net/0.gif?a8mat=358WX8+G4HNLE+E0Q+CUQYA"
      alt=""
    />
  </a>
)

export const Rarejob = () => (
  <a
    href="https://px.a8.net/svt/ejp?a8mat=2ZH27V+8J7OXE+1SVU+686ZM"
    rel="nofollow"
  >
    レアジョブ英会話
    <img
      border="0"
      width="1"
      height="1"
      src="https://www15.a8.net/0.gif?a8mat=2ZH27V+8J7OXE+1SVU+686ZM"
      alt=""
    />
  </a>
)
export const Eigox = () => (
  <a
    href="https://px.a8.net/svt/ejp?a8mat=2ZJ4MA+69NB1U+2TKO+5ZU2A"
    rel="nofollow"
  >
    ネイティブ講師のオンライン英会話【エイゴックス】
    <img
      border="0"
      width="1"
      height="1"
      src="https://www15.a8.net/0.gif?a8mat=2ZJ4MA+69NB1U+2TKO+5ZU2A"
      alt=""
    />
  </a>
)

export const Tsushinsei = () => (
  <a
    href="https://t.felmat.net/fmcl?ak=M98A.1.u2316B.T50944O"
    target="_blank"
    rel="nofollow noopener"
  >
    通信制高校の一括資料請求
    <img
      src="https://t.felmat.net/fmimp/M98A.u2316B.T50944O"
      width="1"
      height="1"
      alt=""
      style="border:none;"
    />
  </a>
)
