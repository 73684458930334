import React from "react";
import AdSense from 'react-adsense'

export const GoogleAdsense0 = () => (
  <div>
    <AdSense.Google
      style={{ display: 'block' }}
      client="ca-pub-4872440530344915"
      slot="3219942323"
      format="link"
      responsive="true"
    />
  </div>
)

export const GoogleAdsense1 = () => (
  <div>
    <AdSense.Google
      style={{ display: 'block' }}
      client="ca-pub-4872440530344915"
      slot="4223582206"
      format="auto"
      responsive="true"
    />
  </div>
)

export const GoogleAdsense2 = () => (
  <div>
    <AdSense.Google
      style={{ display: 'block' }}
      client="ca-pub-4872440530344915"
      slot="1174595775"
      format="auto"
      responsive="true"
    />
  </div>
)

export const GoogleAdsense3 = () => (
  <div>
    <AdSense.Google
      style={{ display: 'block' }}
      client="ca-pub-4872440530344915"
      slot="4565130159"
      format="auto"
      responsive="true"
    />
  </div>
)

export const GoogleAdsense4 = () => (
  <div>
    <AdSense.Google
      style={{ display: 'block' }}
      client="ca-pub-4872440530344915"
      slot="1721700386"
      format="auto"
      responsive="true"
    />
  </div>
)

export const GoogleAdsenseEnd = () => (
  <div>
    <AdSense.Google
      style={{ display: 'block' }}
      client="ca-pub-4872440530344915"
      slot="3219942323"
      format="auto"
      responsive="true"
    />
  </div>
)
