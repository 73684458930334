import { css } from "styled-components"
import svgHeadingIcon_teacher from "../svg/others/heading-icon_teacher.svg"
import svgHeadingIcon_student from "../svg/others/heading-icon_student.svg"
import svgHeadingIcon_parents from "../svg/others/heading-icon_parents.svg"
import svgHeadingIcon_applicant from "../svg/others/heading-icon_applicant.svg"
import svgHeadingIcon_learner from "../svg/others/heading-icon_learner.svg"
import svgHeadingIcon_tourist from "../svg/others/heading-icon_tourist.svg"
// import authorImg_watson from "../png/profile-watson.png"
// import authorImg_manager from "../png/profile-manager.png"
// import authorImg_teacher from "../png/profile-teacher.png"

const postContentStyle = css`
  margin: 1.5em 0 1em;
  line-height: 1.9;
  a {
    font-weight: bold;
  }

  a:hover {
    text-decoration: underline;
  }
  p {
    margin-bottom: 3.5em;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 40px 0 10px;
    font-weight: 700;
    line-height: 1.5;
  }

  h2 {
    position: relative;
    margin: 55px 0 12px;
    padding: 5px 0 5px 17px;
    font-size: 1.6em;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: inline-block;
      width: 5px;
      height: 100%;
      border-radius: 5px;

  /* 🌟12/1 attrsでrerender解決しないので、一時コメントアウト */
      background: ${props => props.categoryColor};
      /* background: #76ff03; */
    }
  }
  h3 {
    margin: 55px 0 12px;
    font-size: 1.65em;
    border-bottom: solid 1px #EAEAEA;
  }
  h4 {
    position: relative;
    padding-left: 18px;
    font-size: 1.5em;
    &:before {
      position: absolute;
      top: .2em;
      left: 0;
      content: "";
      display: inline-block;
      width: 14px;
      height: 19px;
      background-size: contain;
      background-repeat: no-repeat;
       /* 🌟12/1 attrsでrerender解決しないので、一時コメントアウト */
      ${props => svgHeadingIcon(props)}
    }
  }
  ul, ol {
    margin: 1em 0;
    p {
      margin: 0;
    }
  }
  ul {
    padding-left: 1.2em;
  }
  ul li {
    margin: .4em 0;
    list-style: disc;

    & > *:first-child {
      margin-top: 0
    }

  }
  ul ul {
    margin: 0;
  }
  ol {
    counter-reset: number;
    & > li {
      list-style: none;
      list-style-position: inside;
      position: relative;
      line-height: 17px;
      padding-left: 25px;
      margin: 1em 0;

      &:first-child {
        margin-top: 0.2em;
      }
      &:last-child {
        margin-bottom: 0.2em;
      }

      &:before {
        display: inline-block;
        position: absolute;
        margin-right: 1em;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #5c9ee7;
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        line-height: 18px;
        content: counter(number);
        counter-increment: number;
        background-color: #000;
        /* background: ${props => props.theme.colors.highlight}; */
      }
    }
  }
  strong {
    font-weight: 700;
  }
  em {
    font-style: italic;
  }
  del {
    text-decoration: line-through;
  }
  hr {
    display: block;
    margin: 2em 0;
    border: none;
    border-top: dotted 3px #e1eaf3;
  }
  table {
    display: table;
    border-spacing: 2px;
    border-collapse: separate;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: auto;
    font-size: .9em;
    line-height: 1.4;
    margin: 0 auto 1.5em;
    /* margin-bottom: 1.5em; */

    th {
      background-color: ${props => props.theme.colors.highlight};
      color: #fff;
      padding: 10px 12px;
      text-align: center;
      font-weight: 700;
      &[align="center"] {
        text-align: center;
      }
      &[align="right"] {
        text-align: right;
      }
    }
    td {
      background-color:  ${props => props.theme.colors.whitesmoke};
      color: #000;
      padding: 10px 12px;
    }
    thead tr {
      th:first-child {
        border-radius: 5px 0 0 0;
      }
      th:last-child {
        border-radius: 0 5px 0 0;
      }
    }
    tbody tr:last-child {
      td:first-child {
        border-radius: 0 0 0 5px;
      }
      td:last-child {
        border-radius: 0 0 5px 0;
      }
    }
  }

  u {
    text-decoration: none;
    border-bottom: 1px solid #000;
  }

  blockquote {
    margin: 3em 0;
    font-style: italic;
    background: ${props => props.theme.colors.whitesmoke};
    padding: 1em;
    p {
      margin: .3em 0;
    }
  }

 .gatsby-resp-image-wrapper {
    margin: 1em 0 2em;
    /* border: solid 1px #e5e8ea; */
    /* box-shadow: 0 2px 5px -1px rgba(0,0,0,.25); */
    border-radius: 4px;
    overflow: hidden;
  }
  .twitter-tweet{
    margin: 2em auto;
  }

  /* .gatsby-highlight {
    margin: 1em 0;
  } */
`

// const svgHeadingIcon = props => {
//   if (props === "teacher") {
//     return `
//       background-image: url("${svgHeadingIcon_teacher}")
//     `
//   } else if (props === "student") {
//     return `
//       background-image: url("${svgHeadingIcon_student}")
//     `
//   } else if (props === "parents") {
//     return `
//       background-image: url("${svgHeadingIcon_parents}")
//     `
//   } else if (props === "applicant") {
//     return `
//       background-image: url("${svgHeadingIcon_applicant}")
//     `
//   } else if (props === "learner") {
//     return `
//       background-image: url("${svgHeadingIcon_learner}")
//     `
//   } else if (props === "tourist") {
//     return `
//       background-image: url("${svgHeadingIcon_tourist}")
//     `
//   }
// }


//  🌟12/1 attrsでrerender解決しないので、一時コメントアウト

const svgHeadingIcon = props => {

  if (props.categorySlug === "teacher") {
    return `
      background-image: url("${props.siteUrl}/svgs/heading-icon_teacher.svg")
    `
  } else if (props.categorySlug === "student") {
    return `
      background-image: url("${props.siteUrl}/svgs/heading-icon_student.svg")
    `
  } else if (props.categorySlug === "parents") {
    return `
      background-image: url("${props.siteUrl}/svgs/heading-icon_parents.svg")
    `
  } else if (props.categorySlug === "applicant") {
    return `
      background-image: url("${props.siteUrl}/svgs/heading-icon_applicant.svg")
    `
  } else if (props.categorySlug === "learner") {
    return `
      background-image: url("${props.siteUrl}/svgs/heading-icon_learner.svg")
    `
  } else if (props.categorySlug === "tourist") {
    return `
      background-image: url("${props.siteUrl}/svgs/heading-icon_tourist.svg")
    `
  }
}

export default postContentStyle
