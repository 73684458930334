import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Image from "./Image"

const Wrapper = styled.div`
  /* position: sticky; */
  /* top: 12%; */
  /* top: 70%; */
  /* background-color: #fc9842;
  background-image: linear-gradient(315deg, #fc9842 0%, #fe5f75 74%); */
  z-index: 6;
  border-radius: 4px;
  /* margin: -50px 0; */

  background: #f7f7f7;
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #fc9842, #fe5f75); */
  background-size: 400% 400%;
  animation: gradientBG 7s ease infinite;

  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  padding: 20px;

  @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    padding: 10px;
    bottom: 75%;
  }

  .suggest-text {
    color: #000;
    font-weight: 700;
    font-size: 1.2em;
    letter-spacing: 0.05em;
    border-bottom: 1px solid;
    border-color: #000;
    /* text-align: center; */
    margin-bottom: 15px;
  }
`

const PostCardWrapper = styled.div`
  .post-card-link {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    /* color: ${(props) => props.theme.colors.blackLight}; */
    color: #000;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
    &:hover {
      background: #e0ebf1;
    }
    @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
      padding: 10px;
    }
  }
`

const PostCardContent = styled.div`
  /* 右側に空白ができてしまったので（画像入れてないので）、コメントアウトした */
  /* width: calc(100% - 80px); */
  /* background: #fff; */
  padding: 10px 20px;
  h5 {
    font-size: 1.1em;
    font-weight: 700;
    line-height: 1.45;
  }
  time {
    display: block;
    margin-bottom: 0.1em;
    letter-spacing: 0.05em;
    font-size: 0.8em;
    color: ${(props) => props.theme.colors.silver};
  }
  @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    padding-left: 15px;
    h5 {
      font-size: 1em;
    }
  }
`

const LinkPostCard = styled.div`
  display: flex;
  padding: 10px 15px;
  margin-bottom: 15px;
  width: 100%;
  /* border-radius: 7px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.1); */

  color: #000;
  font-family: "M PLUS Rounded 1c", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN",
    "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", sans-serif;
  font-weight: bold;
`

const RelatedPostCard = ({ node }) => {
  // const imageUrl = node.frontmatter.featuredImage.childImageSharp.sizes.src
  const title = node.frontmatter.title || node.fields.slug
  const filename = `${node.fields.slug.slice(1)}0.jpg`

  return (
    <PostCardWrapper>
      <LinkPostCard>
        <Link
          to={node.fields.slug}
          style={{
            fontFamily: "'ヒラギノ明朝 ProN','Hiragino Mincho ProN',sans-serif",
            fontSize: "1.2em",
            color: "#000",
            flex: 1,
            padding: "0 15px 0 0",
          }}
        >
          <p>{title}</p>
        </Link>
        <Image
          filename={filename}
          alt={title}
          width="40%"
          height="100%"
          style={{ borderRadius: "10px" }}
        />
      </LinkPostCard>
    </PostCardWrapper>
  )
}

const SuggestedPosts = ({ posts, categorySlug }) => {
  if (!posts.length) return null
  if (posts.length < 3) return null
  if (categorySlug === "self") return null

  let content = []
  content.push(<p className="suggest-text">こちらの記事もオススメ🎉</p>)

  for (let i = 0; i < 3; i++) {
    content.push(
      <RelatedPostCard key={`suggested_${i}`} node={posts[i].node} />
    )
  }
  return <Wrapper>{content}</Wrapper>
}

export default SuggestedPosts
